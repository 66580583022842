<template>
  <v-container>

    <dlc></dlc>
  </v-container>
</template>
<script>
import dlc from "@/components/denucialegal/dlc.vue";
export default {
  components: { dlc },
};
</script>